import React, { Component } from 'react';
import { Table, Spinner, Form } from 'react-bootstrap';
import AppNavbar from '../common/header';
import BaseRequest from '../Api/Base';
import "./css/common.css";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Sidebar from './components/LeftSidebar';
import sidebarItems from './components/sidebarItems.json';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchForm from './components/search_box';

class PublishedInstruments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instruments: [],
      loading: false,
      page: 1,
      hasMore: true,
      searchQuery: '',
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.fetchInstruments = this.fetchInstruments.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.api = new BaseRequest();
  }

  componentDidMount() {
    this.fetchInstruments();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  async fetchInstruments() {
    if (this.state.loading || !this.state.hasMore) return;
    this.setState({ loading: true });
    const { page, searchQuery } = this.state;
    const $payload = {
      page: this.state.page,
      searchQuery: this.state.searchQuery,
      type: "all",
    };
    try {
      var data = await this.api.get_symbols($payload);
      data = data.data
      console.log('API response:', data);

      if (data && data.length) {
        this.setState((prevState) => ({
          instruments: [...prevState.instruments, ...data],
          hasMore: data.length > 0,
          loading: false,
        }));
      } else {
        this.setState({ loading: false, hasMore: false });
      }
    } catch (error) {
      console.error('Error fetching instruments:', error);
      this.setState({ loading: false });
    }
  }

  handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || this.state.loading) return;
    this.setState((prevState) => ({ page: prevState.page + 1 }), this.fetchInstruments);
  }

  handleSearchChange(event) {
    this.setState({ searchQuery: event.target.value });
  }

  handleSearchSubmit(event) {
    event.preventDefault();
    this.setState({ instruments: [], page: 1, hasMore: true }, this.fetchInstruments);
  }

  humanize(timestampStr) {
    return moment(timestampStr).fromNow();
  }

  render() {
    return (
      <div className="d-flex">
        <div className='flex-fill'>
          <Sidebar items={sidebarItems} />
        </div>
        <div className="flex-fill mt-4 w-100">

          <h1 className="mb-4">Published Instruments</h1>

          <div className="list-container mt-1">
            <div className='container text-left p-0 m-0 mb-2'>
              <a href={"/publish-new-symbol"} className='bg-dark btn btn-primary btn-sm text-left'>
                <AddCircleOutlineIcon /> <span>Publish New Symbol</span>
              </a>
            </div>
            <SearchForm
              searchQuery={this.state.searchQuery}
              handleSearchChange={this.handleSearchChange}
              handleSearchSubmit={this.handleSearchSubmit}
              placeholder="Enter symbol..."
              className="my-custom-class"
            />
            {this.getInstruments()}
          </div>
        </div>
      </div>
    );
  }

  getInstruments() {
    console.log(this.state.instruments)
    return (
      <div className="table-container">
        <Table striped bordered hover responsive="lg">
          <thead>
            <tr>
              <th>Instrument Token</th>
              <th>Updated At</th>
              <th>Trade</th>
            </tr>
          </thead>
          <tbody>
            {this.state.instruments.map((instrument) => (
              <tr key={instrument.instrument_token}>
                <td>{instrument.instrument_token}</td>
                <td>{this.humanize(instrument.updated_at)}</td>
                <td>
                  <a href={"/logic-builder?symbol=" + (instrument.instrument_token ?? instrument.name)} className='btn btn-sm btn-primary text-white py-0'>Logic</a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {this.state.loading && (
          <div className="text-center my-3">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        {!this.state.hasMore && !this.state.loading && (
          <p className="mt-3 text-center">No more instruments to load.</p>
        )}
      </div>
    );
  }
}

export default PublishedInstruments;
