import React, { useState } from 'react';
import ConditionGroup from './ConditionGroup';
import './logic.css';

const StrategyForm = () => {
  const [conditionGroups, setConditionGroups] = useState([]);

  const addGroup = () => {
    setConditionGroups([
      ...conditionGroups,
      {
        id: Date.now(),
        type: 'group',
        logicalOperator: 'AND',
        conditions: [],
        action: 'Skip', // Default action for a new group
      },
    ]);
  };

  const handleGroupChange = (index, updatedGroup) => {
    const newGroups = [...conditionGroups];
    newGroups[index] = updatedGroup;
    setConditionGroups(newGroups);
  };

  const deleteGroup = (id) => {
    setConditionGroups(conditionGroups.filter((group) => group.id !== id));
  };

  return (
    <form>
      <h2>Strategy Conditions</h2>

      {conditionGroups.map((group, index) => (
        <ConditionGroup
          key={group.id}
          group={group}
          signals={[{ id: 1, type: 'Signal A' }, { id: 2, type: 'Signal B' }]} // Example signal options
          onGroupChange={(updatedGroup) => handleGroupChange(index, updatedGroup)}
          onDeleteGroup={() => deleteGroup(group.id)}
          showOperator={index > 0} // Show logical operator for all except the first group
        />
      ))}

      <button type="button" className="add-group-button" onClick={addGroup}>
        Add Condition Group
      </button>
    </form>
  );
};

export default StrategyForm;
