import React, { Component } from 'react';
import { Table, Spinner, Form, Button } from 'react-bootstrap';
import AppNavbar from '../common/header';
import BaseRequest from '../Api/Base';
import "./css/common.css"
import "./css/settings.css"
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sidebar from './components/LeftSidebar';
import sidebarItems from './components/sidebarItems.json';
import { toast } from '../utils';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
        request_id: null,
        token: null,
        error: null,
        token_updated: false,
        current_token: false,
    };

    // this.handleScroll = this.handleScroll.bind(this);
    // this.fetchInstruments = this.fetchInstruments.bind(this);
    // this.handleSearchChange = this.handleSearchChange.bind(this);
    // this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.api = new BaseRequest();
  }

  setrequest_id(request_id) {
    this.setState({ request_id });
  }
  setToken(token) {
    this.setState({ token });
  }

  setError(error) {
    this.setState({ error });
  }


  componentDidMount() {
    // this.fetchInstruments();
    var $parent = this;
    this.api.get(`/api/market/cached_token`).then(function(token) {
      console.log(token);
      $parent.setState({ current_token: token.token });
    })
    // window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

  render() {
    return (
      <div className="d-flex justify-content-start">
      <div className='flex-fill'>
        <Sidebar items={sidebarItems} />
      </div>
        <div className="container-fluid flex-fill mt-4 w-100 ">
          <h1 className="mb-4">Settings</h1>
          {this.getSettingsView()}
        </div>
      </div>
    );
  }

  getSettingsView() {
    const handleInputChange = (e) => {
        this.setState({ request_id: e.target.value });
    };
    
      const handleFormSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await this.api.post(`api/market/generate_token?request_id=${request_id}`, { request_id });
          if (response.data) {
            toast("Error updating token: " + response.data, "error");

            this.setState({ 
              error: response.msg,  // Clear any previous errors
              token_updated: true,
            });
          }

        } catch (error) {
          toast("Error updating token: " + this.api.errorData || "Unknown error occurred", "error");
          console.log(this.api)
          this.setState({ 
            error: this.api.errorData || "Unknown error occurred",
            token_updated: false,
          });
        }
    };
    var error= this.state.error;
    var token = this.state.token;
    var request_id = this.state.request_id;
    return (
        <div>
          <p className='py-2'>Current Token: {this.state.current_token}</p>
        <form onSubmit={handleFormSubmit}>
          <label>
            Request ID:
            <input
              type="text"
              value={request_id}
              onChange={handleInputChange}
              required
            />
          </label>
          <button type="submit" disabled={this.state.token_updated}>Update Token</button>
        </form>
        {token && <p>Token updated: {token}</p>}
        {error && <p style={{ color: 'red' }}> {error}</p>}
      </div>
    );
  }
}

export default Settings;
