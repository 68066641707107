import React from 'react';
import ConditionBlock from './ConditionBlock';

const ConditionGroup = ({
  group,
  signals,
  onGroupChange,
  onDeleteGroup,
  showOperator,
  onOperatorChange,
  isNested = false,
}) => {
  const handleConditionChange = (index, updatedCondition) => {
    const updatedConditions = [...group.conditions];
    updatedConditions[index] = updatedCondition;
    onGroupChange({ ...group, conditions: updatedConditions });
  };

  const handleAddCondition = () => {
    const newCondition = {
      id: Date.now(),
      type: 'ohlc',
      operator: '>',
      valueType: 'integer',
      value: '',
      logicalOperator: 'AND',
    };
    onGroupChange({
      ...group,
      conditions: [...group.conditions, newCondition],
    });
  };

  const handleAddGroup = () => {
    const newGroup = {
      id: Date.now(),
      type: 'group',
      logicalOperator: 'AND',
      conditions: [],
      action: 'Skip', // Default action for new groups
    };
    onGroupChange({
      ...group,
      conditions: [...group.conditions, newGroup],
    });
  };

  const handleActionChange = (e) => {
    const newAction = e.target.value;
    onGroupChange({ ...group, action: newAction });
  };

  const deleteCondition = (id) => {
    const updatedConditions = group.conditions.filter((condition) => condition.id !== id);
    onGroupChange({ ...group, conditions: updatedConditions });
  };

  return (
    <div className={`condition-group ${isNested ? 'nested-group' : ''}`}>
      {showOperator && (
        <div className="operator-item">
          <label>Logical Operator:</label>
          <select value={group.logicalOperator || 'AND'} onChange={onOperatorChange}>
            <option value="AND">AND</option>
            <option value="OR">OR</option>
          </select>
        </div>
      )}

      <div className="group-content">
        {group.conditions.map((condition, index) =>
          condition.type === 'group' ? (
            <ConditionGroup
              key={condition.id}
              group={condition}
              signals={signals}
              onGroupChange={(updatedGroup) => handleConditionChange(index, updatedGroup)}
              onDeleteGroup={() => deleteCondition(condition.id)}
              showOperator={index > 0}
              onOperatorChange={(e) => handleConditionChange(index, { ...condition, logicalOperator: e.target.value })}
              isNested={true}
            />
          ) : (
            <ConditionBlock
              key={condition.id}
              condition={condition}
              signals={signals}
              onConditionChange={(updatedCondition) => handleConditionChange(index, updatedCondition)}
              onDeleteCondition={() => deleteCondition(condition.id)}
              showOperator={index > 0}
              onOperatorChange={(e) => handleConditionChange(index, { ...condition, logicalOperator: e.target.value })}
            />
          )
        )}
      </div>

      {/* Action dropdown for this group */}
      <div className="action-item">
        <label>Action:</label>
        <select value={group.action || 'Skip'} onChange={handleActionChange}>
          <option value="Buy">Buy</option>
          <option value="Sell">Sell</option>
          <option value="Skip">Skip</option>
        </select>
      </div>

      <div className="group-buttons">
        <button type="button" className="add-condition-button" onClick={handleAddCondition}>
          Add Condition
        </button>
        <button type="button" className="add-group-button" onClick={handleAddGroup}>
          Add Condition Group
        </button>
      </div>

      {!isNested && (
        <button type="button" className="delete-group-button" onClick={onDeleteGroup}>
          Delete Group
        </button>
      )}
    </div>
  );
};

export default ConditionGroup;
