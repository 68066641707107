import React, { useState } from 'react';

const ConditionBlock = ({ condition, signals, onConditionChange, onDeleteCondition, showOperator, onOperatorChange }) => {
  const [conditionType, setConditionType] = useState(condition.type || 'ohlc');
  const [ohlcType, setOhlcType] = useState(condition.ohlc?.type || 'close');
  const [ohlcPeriod, setOhlcPeriod] = useState(condition.ohlc?.period || 'n-1');
  const [selectedSignal, setSelectedSignal] = useState(condition.signal || '');
  const [valueType, setValueType] = useState(condition.valueType || 'integer');
  const [adjustment, setAdjustment] = useState(0);

  const handleConditionTypeChange = (e) => {
    const newConditionType = e.target.value;
    setConditionType(newConditionType);
    onConditionChange({
      ...condition,
      type: newConditionType,
      ohlc: newConditionType === 'ohlc' ? { type: ohlcType, period: ohlcPeriod } : null,
      signal: newConditionType === 'signal' ? selectedSignal : null,
    });
  };

  const handleOhlcTypeChange = (e) => {
    const newOhlcType = e.target.value;
    setOhlcType(newOhlcType);
    if (conditionType === 'ohlc') {
      onConditionChange({
        ...condition,
        ohlc: { type: newOhlcType, period: ohlcPeriod },
      });
    }
  };

  const handleOhlcPeriodChange = (e) => {
    const newOhlcPeriod = e.target.value;
    setOhlcPeriod(newOhlcPeriod);
    if (conditionType === 'ohlc') {
      onConditionChange({
        ...condition,
        ohlc: { type: ohlcType, period: newOhlcPeriod },
      });
    }
  };

  const handleSignalChange = (e) => {
    const newSignal = e.target.value;
    setSelectedSignal(newSignal);
    if (conditionType === 'signal') {
      onConditionChange({
        ...condition,
        signal: newSignal,
      });
    }
  };

  const handleOperatorChange = (e) => {
    onConditionChange({ ...condition, operator: e.target.value });
  };

  const handleValueTypeChange = (e) => {
    const newValueType = e.target.value;
    setValueType(newValueType);

    if (newValueType === 'integer') {
      onConditionChange({ ...condition, valueType: newValueType, value: '' });
    } else if (newValueType === 'current') {
      onConditionChange({ ...condition, valueType: newValueType, value: 'currentPrice' });
    } else if (newValueType === 'current+x' || newValueType === 'current-x') {
      onConditionChange({ ...condition, valueType: newValueType, value: `currentPrice + ${adjustment}` });
    }
  };

  const handleAdjustmentChange = (e) => {
    const newAdjustment = parseFloat(e.target.value) || 0;
    setAdjustment(newAdjustment);

    if (valueType === 'current+x') {
      onConditionChange({ ...condition, value: `currentPrice + ${newAdjustment}` });
    } else if (valueType === 'current-x') {
      onConditionChange({ ...condition, value: `currentPrice - ${newAdjustment}` });
    }
  };

  return (
    <div className="condition-block">
      <div className="condition-content">
        {showOperator && (
          <div className="condition-item operator-item">
            <label>Logical Operator:</label>
            <select value={condition.logicalOperator || 'AND'} onChange={onOperatorChange}>
              <option value="AND">AND</option>
              <option value="OR">OR</option>
            </select>
          </div>
        )}

        {/* Condition Type Selector (OHLC or Signal) */}
        <div className="condition-item">
          <label>Condition Type:</label>
          <select value={conditionType} onChange={handleConditionTypeChange}>
            <option value="ohlc">OHLC</option>
            <option value="signal">Signal</option>
          </select>
        </div>

        {/* OHLC Fields */}
        {conditionType === 'ohlc' && (
          <>
            <div className="condition-item">
              <label>OHLC Type:</label>
              <select value={ohlcType} onChange={handleOhlcTypeChange}>
                <option value="open">Open</option>
                <option value="high">High</option>
                <option value="low">Low</option>
                <option value="close">Close</option>
              </select>
            </div>

            <div className="condition-item">
              <label>OHLC Period:</label>
              <select value={ohlcPeriod} onChange={handleOhlcPeriodChange}>
                <option value="n-1">N-1 (Previous Candle)</option>
                <option value="n-2">N-2</option>
                <option value="n-3">N-3</option>
              </select>
            </div>
          </>
        )}

        {/* Signal Fields */}
        {conditionType === 'signal' && (
          <div className="condition-item">
            <label>Signal:</label>
            <select value={selectedSignal} onChange={handleSignalChange}>
              {signals.map((signal) => (
                <option key={signal.id} value={signal.type}>
                  {signal.type}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Operator */}
        <div className="condition-item">
          <label>Operator:</label>
          <select value={condition.operator} onChange={handleOperatorChange}>
            <option value=">">Greater than</option>
            <option value="<">Less than</option>
            <option value="=">Equal to</option>
          </select>
        </div>

        {/* Value Type */}
        <div className="condition-item">
          <label>Value Type:</label>
          <select value={valueType} onChange={handleValueTypeChange}>
            <option value="integer">Integer</option>
            <option value="current">Current Price</option>
            <option value="current+x">Current Price + x</option>
            <option value="current-x">Current Price - x</option>
          </select>
        </div>

        {/* Integer Value */}
        {valueType === 'integer' && (
          <div className="condition-item">
            <input
              type="number"
              value={condition.value}
              onChange={(e) => onConditionChange({ ...condition, value: e.target.value })}
              placeholder="Enter value"
            />
          </div>
        )}

        {/* Adjustment Value for current+x and current-x */}
        {(valueType === 'current+x' || valueType === 'current-x') && (
          <div className="condition-item">
            <label>Adjustment (x):</label>
            <input
              type="number"
              value={adjustment}
              onChange={handleAdjustmentChange}
              placeholder="Enter adjustment"
            />
          </div>
        )}
      </div>

      {/* Delete Button on the far right */}
      <button
        type="button"
        className="delete-button"
        onClick={onDeleteCondition}
      >
        Delete
      </button>
    </div>
  );
};

export default ConditionBlock;
