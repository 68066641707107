import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from './homepage';
import 'normalize.css'; // Reset styles for consistent baseline
import 'bootstrap/dist/css/bootstrap.min.css';
import PaperTradingList from './pages/PaperTradingList';
import InstrumentList from './pages/InstrumentList';
import LogicBuilder from './pages/LogicBuilder';
import Settings from './pages/Settings';
import WorkflowCreate from './pages/Workflow/WorkflowCreate';
import AppNavbar from './common/header.js';
import Modal from 'react-modal';
import WorkflowList from './pages/Workflow/WorkflowList.jsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import MarketEvents from './pages/MarketEvents.js';
import Login from './pages/login.js';
import PythonList from './pages/Python/ListPython.jsx';
import EditorPythonWrapper from './pages/Python/EditorPython.jsx';
import SymbolPublisher from './pages/home/publish_new_symbol.jsx';
import ChartsView from './pages/charts/chart_view.jsx';
import PublishedInstruments from './pages/published_instruments.js';

const isProd = process.env.NODE_ENV === 'production';
Modal.setAppElement('#root');

export default function App() {
  return (
    <BrowserRouter>
      <Helmet>
        {isProd && (
          <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
        )}
      </Helmet>
      <div className="app-container">
        <div className="content-container px-0">
          <Routes>
            <Route path="/" element={<PublishedInstruments />} />
            <Route path="/publish-new-symbol" element={<SymbolPublisher />} />
            <Route path="/paper-trading" element={<PaperTradingList />} />
            <Route path="/market-instruments" element={<InstrumentList />} />
            <Route path="/logic-builder" element={<LogicBuilder />} />
            <Route path="/python-code/list" element={<PythonList />} />
            <Route path="/python-code/edit/:id" element={<EditorPythonWrapper />} />
            <Route path="/logic-flow/lists" element={<WorkflowList />} />
            <Route path="/logic-flow/create" element={<WorkflowCreate />} />
            <Route path="/logic-flow/edit/:id" element={<WorkflowCreate />} />
            <Route path="/data-collectors" element={<LogicBuilder />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/market/events" element={<MarketEvents />} />
            <Route path="/login" element={<Login />} />
            <Route path="/charts" element={<ChartsView />} />
          </Routes>
          <ToastContainer />
        </div>
      </div>
    </BrowserRouter>
  );
}
